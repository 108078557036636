import { useCallback, useEffect, useState } from 'react'
import Script from 'next/script'
import { fontFamily } from 'tailwindcss/defaultTheme'

import { playfair, roboto } from '@/components/layout/Theme'

import { useStore } from '@/contexts/store'

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    ShopifyBuy: any
  }
}

type BuyButtonProps = {
  id: string
  node: string
}

let shopifyClient: any = null

const BuyButton = ({ id, node }: BuyButtonProps) => {
  const [shopifyInit, setShopifyInit] = useState(false)
  const { strings } = useStore()

  const handleScriptLoad = useCallback(() => {
    setShopifyInit(true)
  }, [])

  useEffect(() => {
    if (!window.ShopifyBuy) return
    setShopifyInit(true)
  }, [])

  useEffect(() => {
    if (!shopifyInit) return

    const ShopifyBuy = window.ShopifyBuy

    let ShopifyBuyUI: any = undefined

    if (!shopifyClient) {
      shopifyClient = ShopifyBuy.buildClient({
        domain: strings?.setup?.clientDomain,
        storefrontAccessToken: strings?.setup?.apiKey
      })
    }

    ShopifyBuy.UI.onReady(shopifyClient).then(function (ui: any) {
      ShopifyBuyUI = ui
      ShopifyBuyUI.createComponent('product', {
        id,
        node: document.getElementById(node),
        moneyFormat: strings?.clientData?.formatoValuta,
        options: {
          product: {
            iframe: false,
            contents: {
              img: false,
              title: false,
              price: false,
              options: false,
              description: false,
              button: true
            },
            templates: {
              button: `<button class={{data.classes.product.button}}><span data-label="${strings?.shopifyStrings.product.button}"><span>${strings?.shopifyStrings.product.button}</span></span></button>`
            },
            classes: {
              button: 'shopify-product-button'
            },
            styles: {
              product: {
                '@media (min-width: 601px)': {
                  'max-width': 'calc(25% - 20px)',
                  'margin-left': '20px',
                  'margin-bottom': '50px'
                }
              },
              button: {
                ':hover': {
                  'background-color': '#a67b07'
                },
                'background-color': '#b88908',
                'border-radius': '0px',
                ':focus': {
                  'background-color': '#a67b07'
                }
              }
            },
            buttonDestination: 'modal',
            text: {
              button: strings?.shopifyStrings.product.button
            }
            /* events: {
              addVariantToCart: (product: any) => {
                console.log('addVariantToCart', product)
              },
              updateQuantity: (product: any) => {
                console.log('updateQuantity', product)
              },
              openModal: (product: any) => {
                console.log('openModal', product)
              },
              openOnlineStore: (product: any) => {
                console.log('openOnlineStore', product)
              },
              openCheckout: (product: any) => {
                console.log('openCheckout', product)
              }
            } */
          },
          productSet: {
            styles: {
              products: {
                '@media (min-width: 601px)': {
                  'margin-left': '-20px'
                }
              }
            }
          },
          modalProduct: {
            contents: {
              img: false,
              description: false,
              imgWithCarousel: true,
              button: false,
              buttonWithQuantity: true
            },
            styles: {
              title: {
                'font-family': `${playfair.style.fontFamily}, ${fontFamily.serif}`,
                'font-size': '2.104rem',
                'line-height': '100%',
                'letter-spacing': '0rem',
                'font-weight': '400'
              },
              price: {
                'font-family': `${roboto.style.fontFamily}, ${fontFamily.sans}`,
                'font-size': '14px',
                'line-height': '150%',
                'letter-spacing': '0.32px',
                'font-weight': '400'
              },
              product: {
                '@media (min-width: 601px)': {
                  'max-width': '100%',
                  'margin-left': '0px',
                  'margin-bottom': '0px'
                }
              },
              button: {
                ':hover': {
                  'background-color': '#a67b07'
                },
                'background-color': '#b88908',
                ':focus': {
                  'background-color': '#a67b07'
                }
              },
              description: {
                'font-family': `${roboto.style.fontFamily}, ${fontFamily.sans}`,
                'font-size': '14px',
                'line-height': '150%',
                'letter-spacing': '0.32px',
                'font-weight': '400'
              }
            },
            text: {
              button: strings?.shopifyStrings.modal.button,
              outOfStock: strings?.shopifyStrings.modal.outOfStock,
              unavailable: strings?.shopifyStrings.modal.unavailable
            }
            /* events: {
              closeModal: (modal: any) => {
                console.log('closeModal', modal)
              }
            } */
          },
          option: {},
          cart: {
            iframe: true,
            popup: false,
            styles: {
              cart: {
                'font-family': `${roboto.style.fontFamily}, ${fontFamily.sans}`,
                'background-color': '#f4f2f1'
              },
              title: {
                'font-family': `${playfair.style.fontFamily}, ${fontFamily.serif}`,
                'font-size': '1.6rem',
                'line-height': '100%',
                'letter-spacing': '0rem',
                'font-weight': '400'
              },
              footer: {
                'background-color': '#f4f2f1'
              },
              button: {
                'font-family': `${roboto.style.fontFamily}, ${fontFamily.sans}`,
                'border-radius': '0px',
                ':hover': {
                  'background-color': '#a67b07'
                },
                'background-color': '#b88908',
                ':focus': {
                  'background-color': '#a67b07'
                }
              }
            },
            text: {
              title: strings?.shopifyStrings.cart.title,
              empty: strings?.shopifyStrings.cart.empty,
              button: strings?.shopifyStrings.cart.button,
              total: strings?.shopifyStrings.cart.total,
              currency: strings?.shopifyStrings.cart.currency,
              notice: strings?.shopifyStrings.cart.notice,
              noteDescription: strings?.shopifyStrings.cart.noteDescription
            }
            /* events: {
              openCheckout: (cart: any) => {
                console.log('openCheckout', cart)
              },
              updateItemQuantity: (cart: any) => {
                console.log('updateItemQuantity', cart)
              }
            } */
          },
          lineItem: {
            styles: {
              itemTitle: {
                'font-family':
                  '__Playfair_Display_f4f990, __Playfair_Display_Fallback_f4f990',
                'font-size': '1.2rem'
              },
              price: {},
              quantityDecrement: {
                'border-color': 'rgba(181,139,30,0.3)'
              },
              quantityInput: {
                'border-color': 'rgba(181,139,30,0.3)'
              },
              quantityIncrement: {
                'border-color': 'rgba(181,139,30,0.3)'
              }
            }
          },
          toggle: {
            styles: {
              toggle: {
                'border-radius': '0px',
                'z-index': '30',
                'font-family':
                  '__Playfair_Display_f4f990, __Playfair_Display_Fallback_f4f990',
                'background-color': '#b88908',
                ':hover': {
                  'background-color': '#a67b07'
                },
                ':focus': {
                  'background-color': '#a67b07'
                }
              }
            }
          }
        }
      })
    })

    return () => {
      if (ShopifyBuyUI) {
        ShopifyBuyUI.destroyComponent('product')
        ShopifyBuyUI.destroyComponent('toggle')
        ShopifyBuyUI.destroyComponent('modal')
        ShopifyBuyUI.destroyComponent('cart')
      }
    }
  }, [id, node, strings, shopifyInit])

  return (
    <Script src={strings?.setup?.jsStoreSnippet} onLoad={handleScriptLoad} />
  )
}

export default BuyButton
