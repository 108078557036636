import { useEffect } from 'react'

import BuyButton from '@/components/base/BuyButton'
import Builder from '@/components/layout/Builder'
import Breadcrumbs from '@/components/sections/Breadcrumbs'
import ProductCertifications from '@/components/sections/ProductCertifications'
import ProductHero from '@/components/sections/ProductHero'
import Spacer from '@/components/sections/Spacer'
import usePagePrecache from '@/hooks/usePagePrecache'
import { EMITTER, EVENTS } from '@/constants'

import { getFeaturedImagePrecacheMedia } from './utils'
import { useStore } from '@/contexts/store'
// import { useStore } from '@/contexts/store'
import type { WPPageData } from '@/types'

type ProductLeafPageProps = {
  pageData: WPPageData
}

const ProductLeafPage = ({ pageData }: ProductLeafPageProps) => {
  const { strings } = useStore()

  // console.log(pageData.acf.shopifyProdId)
  const shopifyProductId = pageData.acf.shopifyProdId // '7903687245912'
  const shopifyProductNode = shopifyProductId
    ? strings?.clientData?.selettoreBtn || 'shopify-component'
    : undefined

  usePagePrecache(true, getFeaturedImagePrecacheMedia(pageData, 'medium'))

  useEffect(() => {
    EMITTER.emit(EVENTS.DATALAYER.PRODUCT_VIEW, {
      product_name: pageData.title.rendered,
      prid: pageData.acf.shopifyProdId || '0',
      prod_url: pageData.link,
      image_url: pageData.featured_image.url,
      item_category: pageData.tipologia.join(',')
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {shopifyProductId ? (
        <BuyButton id={shopifyProductId} node={shopifyProductNode} />
      ) : null}

      <Spacer theme="light" variant="xs" />
      <Breadcrumbs theme="light" path={pageData.breadcrumbs} />
      <Spacer theme="light" variant="sm" />
      <ProductCertifications {...pageData.acf.certifications} />
      <ProductHero
        theme="light"
        // shopifyProdId={pageData.acf.shopifyProdId}
        image={pageData.featured_image}
        heading={{
          title: pageData.title.rendered,
          subtitle: pageData.acf.subtitle,
          component: 'h1'
        }}
        paragraphRegular={pageData.content.rendered.replace(
          /(<([^>]+)>)/gi,
          ''
        )}
        buttons={{
          shopifyButton: {
            id: shopifyProductNode
          },
          /* primaryButton: {
            title: strings?.shopifyCtaLabel,
            url: '/',
            target: ''
          }, */
          secondaryButton: pageData.acf.dataSheets
            ? {
                title: strings?.dataSheetCtaLabel,
                url: '/',
                target: ''
              }
            : ''
        }}
        sheets={pageData.acf.dataSheets}
        extra={pageData.acf.extra}
      />
      <Spacer theme="light" variant="2xl" />
      <Builder sections={pageData.acf.builder} />
    </>
  )
}

export default ProductLeafPage
