import classNames from 'classnames'

import Container from '@/components/base/Container'
import Grid from '@/components/base/Grid'
import ImageRatio from '@/components/base/ImageRatio'
import Section from '@/components/base/Section'
import TextBlock from '@/components/base/TextBlock'
import Builder from '@/components/layout/Builder'
import Breadcrumbs from '@/components/sections/Breadcrumbs'
import Spacer from '@/components/sections/Spacer'
import type { TextProps } from '@/components/sections/Text'
import usePagePrecache from '@/hooks/usePagePrecache'

import { getFeaturedImagePrecacheMedia } from './utils'
import { WPImage, WPPageData } from '@/types'

type VineyardLeafPageProps = {
  pageData: WPPageData
}

type VineyardLeafHeroProps = TextProps & {
  image: Partial<WPImage>
}

const VineyardLeafHero = ({
  theme,
  alignment,
  compressed,
  indented,
  image,
  ...props
}: VineyardLeafHeroProps) => {
  return (
    <Section theme={theme}>
      <Container>
        <Grid className="gap-y-spacing-lg">
          <TextBlock
            titleVariant={compressed ? 'h4' : 'h2'}
            density={compressed ? 'small' : 'large'}
            rootClassName={classNames(
              {
                'col-span-2 md:col-span-3 lg:col-span-5': !compressed,
                'col-span-2 md:col-span-5 lg:col-span-7': compressed
              },
              {
                'md:col-start-2 lg:col-start-2':
                  indented && alignment === 'text-left'
              },
              {
                'md:col-start-3 lg:col-start-7':
                  indented && !compressed && alignment === 'text-right',
                'md:col-start-3 lg:col-start-8':
                  !indented && !compressed && alignment === 'text-right'
              },
              {
                'md:col-start-2 lg:col-start-6':
                  !indented && compressed && alignment === 'text-right',
                'md:col-start-1 lg:col-start-5':
                  indented && compressed && alignment === 'text-right'
              }
            )}
            {...props}
          />
          <div className="relative col-span-2 md:col-span-3 lg:col-span-5 lg:col-start-8">
            <ImageRatio
              ratio="1:1"
              fit="cover"
              src={image?.sizes?.large.url || image?.url || ''}
              alt={image?.alt || ''}
            />
          </div>
        </Grid>
      </Container>
    </Section>
  )
}

const VineyardLeafPage = ({ pageData }: VineyardLeafPageProps) => {
  usePagePrecache(true, getFeaturedImagePrecacheMedia(pageData, 'large'))

  return (
    <>
      <Spacer theme="light" variant="xs" />
      <Breadcrumbs theme="light" path={pageData.breadcrumbs} />
      <Spacer theme="light" variant="sm" />
      <VineyardLeafHero
        theme="light"
        alignment="text-left"
        compressed={false}
        indented={false}
        heading={{
          overline: '',
          subtitle: '',
          title: pageData.title.rendered,
          component: 'h1'
        }}
        paragraphRegular={pageData.content.rendered}
        image={pageData.featured_image}
      />
      <Builder sections={pageData.acf.builder} />
    </>
  )
}

export default VineyardLeafPage
