import Builder from '@/components/layout/Builder'
import Breadcrumbs from '@/components/sections/Breadcrumbs'
import Spacer from '@/components/sections/Spacer'
import Text from '@/components/sections/Text'
import usePagePrecache from '@/hooks/usePagePrecache'

import { WPPageData } from '@/types'

type NewsroomLeafPageProps = {
  pageData: WPPageData
}

const NewsroomLeafPage = ({ pageData }: NewsroomLeafPageProps) => {
  usePagePrecache(true, [])

  return (
    <>
      <Spacer theme="light" variant="xs" />
      <Breadcrumbs theme="light" path={pageData.breadcrumbs} />
      <Spacer theme="light" variant="sm" />
      <Text heading={{ title: pageData.title.rendered, component: 'h1' }} />
      <Builder sections={pageData.acf.builder} />
    </>
  )
}

export default NewsroomLeafPage
