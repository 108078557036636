import classNames from 'classnames'

import Container from '@/components/base/Container'
import Grid from '@/components/base/Grid'
import Section from '@/components/base/Section'
import Spinner from '@/components/base/Spinner'
import Typography from '@/components/base/Typography'

type LoadMoreProps = Partial<{
  label: string
  hidden: boolean
  fetching: boolean
  onClick: () => void
}>

const LoadMore = ({ label, fetching, onClick }: LoadMoreProps) => {
  return (
    <Section>
      <Container>
        <Grid>
          <div className="col-span-2 md:col-span-6 lg:col-span-12">
            <button
              className="group flex w-full items-center justify-center bg-surface-primay-light px-spacing-xs py-[1.2rem] dark:bg-surface-primay-light"
              onClick={onClick}
            >
              <span className="relative flex">
                <span
                  data-label={label}
                  className={classNames(
                    'relative block overflow-hidden p-[.4rem]',
                    'after:absolute after:left-0 after:top-0 after:-mb-[0.35em] after:-mt-[0.3em] after:w-full after:translate-y-full after:p-[.4rem] after:font-roboto after:text-small after:text-text-button-primary-light after:transition-transform after:duration-300 after:ease-out-cubic after:content-[attr(data-label)] pointer:group-hover:after:translate-y-0 dark:after:text-text-button-primary-dark'
                  )}
                >
                  <Typography
                    variant="small"
                    className={classNames(
                      'text-text-button-primary-light dark:text-text-button-primary-dark',
                      'block transition-transform duration-300 ease-out-cubic pointer:group-hover:-translate-y-full'
                    )}
                  >
                    {label}
                  </Typography>
                </span>
                {fetching ? (
                  <span className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-spacing-md">
                    <Spinner variant="small" />
                  </span>
                ) : null}
              </span>
            </button>
          </div>
        </Grid>
      </Container>
    </Section>
  )
}

export default LoadMore
