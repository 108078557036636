import Builder from '@/components/layout/Builder'
import Breadcrumbs from '@/components/sections/Breadcrumbs'
import Spacer from '@/components/sections/Spacer'
import Text from '@/components/sections/Text'
import usePagePrecache from '@/hooks/usePagePrecache'

import type { WPPageData } from '@/types'

type ProductListPageProps = {
  pageData: WPPageData
}

const ProductListPage = ({ pageData }: ProductListPageProps) => {
  usePagePrecache(true, [])

  return (
    <>
      <Spacer theme="light" variant="xs" />
      <Breadcrumbs theme="light" path={pageData.breadcrumbs} />
      <Spacer theme="light" variant="xs" />
      <Text
        theme="light"
        alignment="text-left"
        compressed={false}
        indented={false}
        heading={{
          overline: '',
          subtitle: '',
          title: pageData.title.rendered,
          component: 'h1'
        }}
        paragraphRegular={pageData.acf.subtitle}
      />
      <Spacer theme="light" variant="lg" />
      <Builder sections={pageData.acf.builder} />
      <Spacer theme="light" variant="2xl" />
    </>
  )
}

export default ProductListPage
