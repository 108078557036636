import { concat, filter, forEach, includes, map, reduce, values } from 'lodash'

import { transformKeysToCamelCase } from '@/utils/object'

import type { WPPageData } from '@/types'

export const addImageRatioToSection = (ratio: string, section: any) => ({
  ...section,
  imageRatio: ratio
})

export const createSpacerSection = (variant: string, theme = 'light') => ({
  acf_fc_layout: 'spacer',
  variant,
  theme
})

export const mapProductsPageStaticProps = (pageData: WPPageData) => {
  const groupProductsByLine = (collection: any[], filters: any[]) => {
    const collectionStore = [...collection]

    const lines = filter(
      filters,
      (category) => category.acf.is_product_line && category.count > 0
    )

    let productsByCategory = reduce(
      lines,
      (result: any, filter) => {
        result[filter.id] = []

        forEach(collection, (product) => {
          const categories = [...product.tipologia]
          if (includes(product.tipologia, filter.id)) {
            collectionStore.splice(collectionStore.indexOf(product), 1)
            categories.splice(product.tipologia.indexOf(filter.id), 1)
            result[filter.id].push({
              ...product,
              line: [filter.id],
              categories
            })
          }
        })

        return result
      },
      {}
    )

    productsByCategory = reduce(
      collectionStore,
      (result, product) => {
        if (!result[product.tipologia]) {
          result[product.tipologia] = []
        }
        result[product.tipologia].push({
          ...product,
          line: [...product.tipologia],
          categories: [...product.tipologia]
        })
        return result
      },
      productsByCategory
    )

    return values(productsByCategory)
  }

  const createProductList = () => ({
    acf_fc_layout: 'product_list',
    theme: 'light',
    lines: groupProductsByLine(pageData.collection.items, pageData.filters),
    collection: pageData.collection.items,
    categories: pageData.filters,
    filters: pageData.filters.map((category: any) => ({
      label: category.name,
      value: category.id
    }))
  })

  return {
    ...pageData,
    acf: transformKeysToCamelCase({
      ...pageData.acf,
      builder: concat([createProductList()])
    })
  }
}

export const mapPageStaticProps = ({ acf, ...pageData }: WPPageData) => {
  const createHeroSection = () => ({
    acf_fc_layout: 'hero',
    heading: { title: pageData.title.rendered, component: 'h1' },
    paragraph_regular: acf.subtitle,
    ...acf.hero
  })

  return {
    ...pageData,
    acf: transformKeysToCamelCase({
      ...acf,
      builder: concat(
        acf?.hero ? [createHeroSection()] : [],
        map(acf?.builder, (section) =>
          'image_text' === section.acf_fc_layout
            ? addImageRatioToSection('1:1', section)
            : 'news_card' === section.acf_fc_layout
              ? addImageRatioToSection('16:9', section)
              : section
        )
      )
    })
  }
}
