import { ReactElement, useMemo } from 'react'
import {
  GetStaticPathsContext,
  GetStaticPropsContext,
  InferGetStaticPropsType,
  NextPageContext
} from 'next'

import Meta from '@/components/base/Meta'
import Layout from '@/components/layout/Layout'
import {
  mapPageStaticProps,
  mapProductsPageStaticProps
} from '@/components/layout/utils'
import NewsletterPage from '@/components/pages/NewsletterPage'
import NewsroomLeafPage from '@/components/pages/NewsroomLeafPage'
import NewsroomPage from '@/components/pages/NewsroomPage'
import PageBuilder from '@/components/pages/PageBuilder'
import ProductLeafPage from '@/components/pages/ProductLeafPage'
import ProductListPage from '@/components/pages/ProductListPage'
import VineyardLeafPage from '@/components/pages/VineyardLeafPage'
import { getPromiseFromFetch } from '@/utils/request'
import { API } from '@/constants'

import type { WPPageData, WPRoutes } from '@/types'

export default function Page(
  pageData: InferGetStaticPropsType<typeof getStaticProps>
) {
  const page = useMemo(() => {
    if (
      pageData.type === 'page' &&
      pageData.template === 'template/wines.php'
    ) {
      return <ProductListPage pageData={pageData} />
    }

    if (
      pageData.type === 'page' &&
      pageData.template === 'template/newsroom.php'
    ) {
      return <NewsroomPage pageData={pageData} />
    }

    if (
      pageData.type === 'page' &&
      pageData.template === 'template/newsletter.php'
    ) {
      return <NewsletterPage pageData={pageData} />
    }

    if (pageData.type === 'vigneto') {
      return <VineyardLeafPage pageData={pageData} />
    }

    if (pageData.type === 'vino') {
      return <ProductLeafPage pageData={pageData} />
    }

    if (pageData.type === 'post') {
      return <NewsroomLeafPage pageData={pageData} />
    }

    return <PageBuilder pageData={pageData} />
  }, [pageData])

  return page
}

Page.getLayout = function getLayout(
  page: ReactElement,
  props: NextPageContext & WPPageData
) {
  return (
    <Layout translations={props.wpml_translations}>
      <Meta pageData={props} />
      {page}
    </Layout>
  )
}

export async function getStaticProps({
  params,
  locale
}: GetStaticPropsContext<{ routes: string[] }>) {
  /**
   * fetch page data.
   */
  const res = await getPromiseFromFetch<WPPageData>({
    method: 'get',
    url: `/${locale}/${params?.routes.join('/')}/`
  })

  if (res.status === 404) return { notFound: true }

  const { data: pageData } = res

  return {
    props: {
      ...(pageData.template === 'template/wines.php'
        ? mapProductsPageStaticProps(pageData)
        : mapPageStaticProps(pageData))
    }
  }
}

export async function getStaticPaths({}: GetStaticPathsContext) {
  const { data: routes } = await getPromiseFromFetch<WPRoutes>({
    method: 'get',
    url: API.ROUTES
  })

  // const routes = require('@/data/routes.json') as WPRoutes

  // const routes = dataRoutes.filter(({ routes, locale }) => routes[0] === locale)

  return {
    paths: routes.map((route) => ({
      params: { routes: route.routes.filter((item) => item !== route.locale) },
      locale: route.locale
    })),
    fallback: 'blocking' // false
  }
}
