import Builder from '@/components/layout/Builder'
import Spacer from '@/components/sections/Spacer'
import Text from '@/components/sections/Text'
import usePagePrecache from '@/hooks/usePagePrecache'

import { WPPageData } from '@/types'

type NewsletterPageProps = {
  pageData: WPPageData
}

const NewsletterPage = ({ pageData }: NewsletterPageProps) => {
  usePagePrecache(true, [])

  return (
    <>
      <Spacer variant="xs" />
      <Text
        alignment="text-left"
        compressed={false}
        indented={false}
        heading={{
          overline: pageData.title.rendered,
          title: pageData.acf?.subtitle,
          component: 'h1'
        }}
      />
      <Builder sections={pageData.acf.builder} />
    </>
  )
}

export default NewsletterPage
