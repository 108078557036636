import { Fragment } from 'react'
import classNames from 'classnames'

import Container from '@/components/base/Container'
import ImageRatio from '@/components/base/ImageRatio'
import LinkButton from '@/components/base/LinkButton'
import Section from '@/components/base/Section'
import Typography from '@/components/base/Typography'

import Spacer from './Spacer'
import { WPImage } from '@/types'

type ProductCertificationProps = Partial<{
  image: Partial<WPImage>
  title: string
  paragraphSmall: string
  buttons: Partial<{
    primaryButton: any
    secondaryButton: any
  }>
}>

type ProductCertificationsProps = Partial<{
  title: string
  showCertifications: boolean
  items: ProductCertificationProps[]
}>

const ProductCertificationCard = ({
  title,
  image,
  buttons,
  paragraphSmall
}: ProductCertificationProps) => {
  return (
    <div
      className={classNames(
        'grow basis-full',
        'flex flex-col items-center space-y-spacing-sm p-spacing-md md:basis-1/2 lg:p-spacing-lg xl:flex-row xl:space-y-0',
        'border-b border-r border-border-thumb-grid-light dark:border-border-thumb-grid-dark'
      )}
    >
      <div
        className={classNames(
          'flex h-full grow basis-full flex-col justify-between space-y-spacing-xs xl:basis-1/2',
          {
            'xl:mr-[1.2rem]': image
          }
        )}
      >
        <div className="flex flex-col space-y-spacing-xs">
          <Typography
            variant="h6"
            component="h6"
            className="text-text-primary-light dark:text-text-primary-dark"
          >
            {title}
          </Typography>
          <Typography
            variant="small"
            paragraph
            className="text-text-primary-light dark:text-text-primary-dark"
          >
            {paragraphSmall}
          </Typography>
        </div>
        {buttons?.primaryButton || buttons?.secondaryButton ? (
          <div className="flex space-x-spacing-xxs">
            {buttons?.primaryButton ? (
              <LinkButton
                variant="contained"
                href={buttons?.primaryButton.url}
                className="whitespace-nowrap"
              >
                {buttons?.primaryButton.title}
              </LinkButton>
            ) : null}
            {buttons?.secondaryButton ? (
              <LinkButton
                variant="outlined"
                href={buttons?.secondaryButton.url}
                className="whitespace-nowrap"
              >
                {buttons?.secondaryButton.title}
              </LinkButton>
            ) : null}
          </div>
        ) : null}
      </div>
      {image ? (
        <div className="flex h-full w-full grow basis-full items-center xl:ml-[1.2rem] xl:basis-1/2">
          <div className="grow">
            <ImageRatio
              src={image?.sizes?.medium.url || image?.url || ''}
              alt={image?.alt || ''}
              ratio="16:9"
              fit="cover"
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}

const ProductCertifications = ({
  title,
  showCertifications,
  items
}: ProductCertificationsProps) => {
  return showCertifications ? (
    <>
      <Section theme="light">
        <Container>
          <div className="flex flex-col space-y-spacing-md">
            <Typography
              variant="h6"
              component="h6"
              className="text-text-primary-light dark:text-text-primary-dark"
            >
              {title}
            </Typography>
            <div className="flex flex-wrap border-l border-t border-border-thumb-grid-light dark:border-border-thumb-grid-dark">
              {items?.map((card, index) => (
                <Fragment key={`card-${index}`}>
                  <ProductCertificationCard
                    key={`certification-card-${index}`}
                    {...card}
                  />
                </Fragment>
              ))}
            </div>
          </div>
        </Container>
      </Section>
      <Spacer theme="light" variant="lg" />
    </>
  ) : null
}

export default ProductCertifications
