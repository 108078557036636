import {
  createRef,
  Fragment,
  RefObject,
  useCallback,
  useEffect,
  useState
} from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'
import classNames from 'classnames'
import { useRouter } from 'next/router'

import Container from '@/components/base/Container'
import Grid from '@/components/base/Grid'
import LinkButton from '@/components/base/LinkButton'
import LoadMore from '@/components/base/LoadMore'
import Section from '@/components/base/Section'
import Select from '@/components/base/Select'
import { mapPageStaticProps } from '@/components/layout/utils'
import { sectionsMap } from '@/components/sections'
import Spacer from '@/components/sections/Spacer'
import Text from '@/components/sections/Text'
import usePagePrecache from '@/hooks/usePagePrecache'
import { useLocaleStore } from '@/store/locale'
import { useRouterStore } from '@/store/router'
import { stripSlash } from '@/utils/path'
import { getPromiseFromFetch } from '@/utils/request'

import { useLenis } from '@/contexts/lenis'
import { WPPageData } from '@/types'

type NewsroomPageProps = {
  pageData: WPPageData
}

type Sections = {
  acfFcLayout: string
  nodeRef: RefObject<HTMLElement>
}[]

const NewsroomPage = ({ pageData }: NewsroomPageProps) => {
  const { lenis } = useLenis()
  const { asPath, locale, push } = useRouter()
  const { acf, filters, collection } = pageData
  const [page, setPage] = useState(1)
  const [loadMore, setLoadMore] = useState(!collection.loadmore.hidden)
  const [fetching, setFetching] = useState(false)
  const [sections, setSections] = useState<Sections>(
    acf.builder.map((section: any) => ({
      ...section,
      nodeRef: createRef<HTMLElement>()
    }))
  )

  const getSections = useCallback(async (page: number) => {
    setFetching(true)

    const route = useRouterStore.getState().currentRoute
    const locale = useLocaleStore.getState().currentLocale

    console.log(`/${locale}${route}page/${page}/`)

    const { data } = await getPromiseFromFetch({
      method: 'get',
      url: `/${locale}${route}page/${page}/`,
      params: {
        params: {
          // items: 2
        }
      }
    })

    const { acf, collection } = mapPageStaticProps(data)

    setFetching(false)
    setLoadMore(!collection?.loadmore.hidden)
    setSections(
      acf.builder.map((section: any) => ({
        ...section,
        nodeRef: createRef<HTMLElement>()
      }))
    )
  }, [])

  useEffect(() => {
    window.dispatchEvent(new Event('resize'))
    lenis?.resize()
  }, [lenis, sections])

  useEffect(() => {
    if (page === 1) return
    getSections(page)
  }, [page, getSections])

  usePagePrecache(true, [])

  return (
    <>
      <Spacer theme="light" variant="sm" />
      <Text
        alignment="text-left"
        heading={{
          overline: pageData.acf.overline,
          subtitle: '',
          title: pageData.title.rendered,
          component: 'h1'
        }}
        paragraphRegular={pageData.acf.subtitle}
      />

      {/* <Builder sections={sections} /> */}

      <Spacer theme="light" variant="lg" />

      <Section theme="light">
        <Container>
          <Grid>
            <div className="col-span-2 md:col-span-6 lg:col-span-12">
              <div className="flex space-x-spacing-xxs">
                {filters?.map(({ name, link, count }: any, index: number) => {
                  const selected =
                    stripSlash(link) === stripSlash(`/${locale}${asPath}`)
                  return (
                    <Fragment key={`filter-${index}`}>
                      {count > 0 ? (
                        <LinkButton
                          href={link}
                          variant="rounded"
                          selected={selected}
                          // selected={value === currentFilter.value}
                          className="hidden md:flex"
                        >
                          {name}
                        </LinkButton>
                      ) : null}
                    </Fragment>
                  )
                })}
              </div>

              <Select
                className="flex md:hidden"
                value={
                  filters?.filter(
                    ({ link }: any) =>
                      stripSlash(link) === stripSlash(`/${locale}${asPath}`)
                  )[0]?.link
                }
                options={filters
                  .filter(({ count }: any) => count > 0)
                  .map(({ name, link }: any) => ({
                    label: name,
                    value: link
                  }))}
                onChange={(event) => {
                  const selectedIndex = event?.target.selectedIndex
                  const filter = filters?.filter(({ count }: any) => count > 0)[
                    selectedIndex
                  ]
                  push(filter.link, undefined, { scroll: false })
                }}
              />
            </div>
          </Grid>
        </Container>
      </Section>

      <TransitionGroup>
        {sections?.map(({ acfFcLayout, nodeRef, ...section }, index) => {
          const Section = sectionsMap[acfFcLayout]
          return (
            <Transition
              key={`newsroom-${index}`}
              nodeRef={nodeRef}
              timeout={0}
              exit={false}
            >
              {(state) => (
                <Section
                  ref={nodeRef}
                  className={classNames({
                    '[&>*]:opacity-0': state === 'entering',
                    '[&>*]:opacity-100 [&>*]:transition-opacity [&>*]:duration-500 [&>*]:ease-in-out-cubic':
                      state === 'entered',
                    '[&>*]:opacity-0 [&>*]:transition-opacity [&>*]:duration-0 [&>*]:ease-in-out-cubic':
                      state === 'exiting'
                  })}
                  {...section}
                />
              )}
            </Transition>
          )
        })}
      </TransitionGroup>

      {loadMore ? (
        <LoadMore
          fetching={fetching}
          onClick={() => setPage((page) => page + 1)}
          {...pageData?.collection.loadmore}
        />
      ) : null}

      <Spacer theme="light" variant="2xl" />
    </>
  )
}

export default NewsroomPage
